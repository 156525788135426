:root {
    --primary: #b61e42;
}
@font-face {
    font-family: 'Arial';
}

body {
    background-color: #f3f3f3;
    margin: 0;
    font-family: 'Arial';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.auth_layout {
    background: url(../src/res/images/background.svg) no-repeat center center
        fixed;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.staff_layout {
    width: auto;
    height: calc(100vh - 64px);
    background-size: cover;
}

.blank_layout {
    width: auto;
    height: 100vh;
    background-color: #ffffff;
}

.ant-switch {
    background-color: #bdbdbd;
}

.ant-switch-checked .ant-switch-inner {
    color: #ffffff;
    font-weight: bold;
}

.ant-switch-inner {
    color: #001529;
    font-weight: bold;
}

.ant-switch-checked {
    background-color: #001529;
}

.ant-table-thead > tr > th.ant-table-cell {
    background-color: #f2f6f8;
    font-weight: bold;
}

.ant-breadcrumb-link {
    font-size: 14px;
}

td > div > img.ant-image-img {
    object-fit: cover;
}
li.ant-pagination-total-text {
    color: #aab0c7;
    font-size: 14px;
}
.ant-pagination.mini .ant-pagination-item {
    min-width: 33px;
    height: 33px;
    margin: 0;
    border-radius: 4px;
    line-height: 33px;
}
.ant-modal-footer {
    padding: 5px 16px 20px 16px;
    text-align: left;
    background: transparent;
    border-top: none;
    border-radius: 0 0 2px 2px;
}
.buttonAddNew {
    height: 40px;
    border-radius: 3px;
}
.image-customer img.ant-image-img {
    border-radius: 6px;
    object-fit: contain;
    border: 1px solid #e7e7e7;
}
.image-search {
    width: 17px;
    object-fit: cover;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 0 11px;
    border-radius: 3px;
}
.label-price .ant-form-item-label > label {
    height: 40px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}
.avatar-news .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 210px;
    border-radius: 6px;
}
.avatar-news .ant-upload.ant-upload-select-picture-card img {
    max-width: 100%;
    max-height: 100%;
}
.ck-editor__editable_inline {
    min-height: 450px;
}
.example-spin {
    text-align: center;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
}
.input-phone-staff .react-tel-input .form-control {
    width: 100% !important;
    border-radius: 3px;
    height: 40px;
    border: 1px solid #d9d9d9;
}
.input-phone-staff .react-tel-input .selected-flag {
    width: 40px;
}
.sufix-number {
    background: #e5edf1;
    border-radius: 3px;
}

.checkbox-right .ant-checkbox .ant-checkbox-inner {
    width: 25px;
    height: 25px;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: unset !important;
}

.table-status-active {
    border: 1px solid #1ed662;
    border-radius: 3px;
    cursor: pointer;
    color: #1ed662;
    padding: 5px 0px;
    text-align: center;
}
.table-status-refuse {
    border: 1px solid red;
    border-radius: 3px;
    cursor: pointer;
    color: red;
    padding: 5px 0px;
    text-align: center;
}
.table-status-inactive {
    border: 1px solid #aab0c7;
    border-radius: 3px;
    cursor: pointer;
    color: #aab0c7;
    padding: 5px 0px;
    text-align: center;
}
.table-status-approved {
    border: 1px solid #1ed662;
    border-radius: 3px;
    cursor: pointer;
    color: #1ed662;
    padding: 5px 0px;
    text-align: center;
}
.table-status-pending {
    border: 1px solid #aab0c7;
    border-radius: 3px;
    cursor: pointer;
    color: #aab0c7;
    padding: 5px 0px;
    text-align: center;
}

.logoCms {
    width: 100%;
    color: white;
    text-align: center;
    font-size: 20px;
    padding-top: 15px;
    font-weight: bold;
    cursor: pointer;
}

.logoImageCms {
    width: 60px;
    object-fit: contain;
    display: block;
    margin: auto;
    margin-top: 15px;
    cursor: pointer;
}

.mutiple-image.ant-upload-picture-card-wrapper {
    display: inline-block;
    width: 200px;
    margin-right: 15px;
    position: relative;
    top: -90px;
}
.mutiple-image-nodata.ant-upload-picture-card-wrapper {
    top: 0 !important;
}

.mutiple-image.ant-upload-picture-card-wrapper .loadingView {
    position: absolute;
    bottom: 10px;
    left: 32%;
}

.mutiple-image .ant-upload.ant-upload-select-picture-card {
    width: 200px;
    height: 200px;
    border-radius: 6px;
}
.mutiple-image .ant-upload.ant-upload-select-picture-card img {
    max-width: 100%;
    max-height: 100%;
}
.list-multiple-image {
    height: 200px;
    margin: 20px 0px;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.multiple-image-item {
    width: 200px;
    height: 200px;
    border-radius: 6px;
    position: relative;
    margin-right: 15px;
    display: inline-block;
}

.multiple-image-item .multiple-image-item-img {
    width: 200px;
    height: 200px;
    object-fit: cover;
}
.multiple-image-item .btn-close img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 25px;
    height: 25px;
}
.multiple-image-item .btn-close {
    cursor: pointer;
}

// upload image component
.avatar-news {
    min-width: 200px;
    max-width: 100%;
}

.ant-layout-sider {
    z-index: 20;
}

.ant-layout-sider.ant-layout-sider-dark {
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
}

.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    width: 80px !important;
    min-width: 80px !important;
    max-width: 80px !important;
}

.ant-layout-sider::-webkit-scrollbar {
    display: none;
}

.render-action-style {
    display: flex;
    justify-content: end;
    align-items: center;
}

.render-action-style > span {
    margin: 0 2px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-75 {
    margin-top: 75px;
}

.titleNonRequired {
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: bold;
}

.titleRequired {
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: bold;
}

.titleRequired::after {
    content: ' *';
    color: red;
}

.btnSearch {
    margin-top: 16px;
}
.custome-table {
    margin: 20px;
    padding: 20px;
    background-color: white;
    border-radius: 6px;
}
.custome-table-staff {
    padding: 20px;
    background-color: white;
    border-radius: 6px;
}
.custome-table-blank {
    padding: 20px;
    background-color: white;
    border-radius: 6px;
}
.ant-btn {
    height: 36px;
    border-radius: 10px;
}
.filter-bar-staff {
    background-color: white;
    padding: 0px 20px;
}
.filter-bar-blank {
    background-color: white;
    padding: 0px 20px;
}
.filter-bar {
    background-color: white;
    padding: 10px 10px;
    margin-left: 0px !important;
    margin-right: 0px !important;

    & .btn-new {
        background-color: #001529;
        border-color: #001529;
    }

    & .ant-input-affix-wrapper {
        line-height: 26px;
        border-radius: 10px;
    }
}
.ant-breadcrumb > ol {
    list-style: none;
}
.ant-spin-nested-loading > div > .ant-spin {
    height: 100vh !important;
    max-height: initial !important;
}
.ant-pagination-item-active a {
    color: #001529;
    border-color: #001529;
}
.ant-pagination-item-active {
    border-color: #001529;
    color: #001529;
}
.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover {
    border-color: #001529;
    color: #001529;
}
.ant-pagination-item a:hover {
    color: #001529;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: #001529;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #001529;
}
.ant-select-selector {
    border-color: #001529;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: #001529;
}
.ant-steps-item-process .ant-steps-item-icon {
    background-color: #001529;
    border-color: #001529;
}
.ant-steps-item-finish .ant-steps-item-icon {
    border-color: #001529;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #001529;
}
.anticon-check svg {
    color: #001529;
}
.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title:after {
    background-color: #001529;
}
.ant-upload-picture-card-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}
.ant-upload-list-picture-card-container,
.ant-upload.ant-upload-select-picture-card {
    width: 240px;
    height: 135px;
}
.ant-upload-list {
    display: flex;
    flex-basis: auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    margin: 0px 4px 4px 4px;
}

.pagination-page {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    margin-top: 15px;
    width: 100%;
}
textarea.ant-input[aria-invalid='true'] {
    border: 1px solid red;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    cursor: pointer;
}
.ant-collapse
    > .ant-collapse-item
    .ant-collapse-header-collapsible-only
    .ant-collapse-header-text {
    flex: auto;
    cursor: pointer;
    margin-inline-end: auto;
}
.title-ed-name {
    font-size: 14px;
}
